
import { Vue } from 'vue-property-decorator';
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import TitleSection from './title-section/title-section.vue';
import ContentElementMetadata from '@/components/forms/content-element-metadata/content-element-metadata.vue';
import ModuleRdv from '@/components/forms/module-rdv/module-rdv.vue';
import { Media } from '@/models/media/media';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { rdvModule } from '@/models/rdv-module/rdv-module';
import { processModule } from '@/models/process/process';
export default Vue.extend({
  components: {
    Tab,
    TabItem,
    TitleSection,
    ContentElementMetadata,
    ImageCard,
    ModuleRdv,
  },
  props: ['editorial'],
  mixins: [build, mediaMixin],
  data() {
    return {
      tabIndex: 0,
      galery: [] as Array<Media>,
      carousel: [] as Array<Media>,
      titleSectionData: {} as any,
      module_rdv: [] as Array<rdvModule>,
      process_module: {} as processModule,
      statutDateAutorData: {
        type: this.$route.params.type,
        status: 'brouillon',
        created_by: '',
        created_at: '',
        updated_at: '',
        published_at: '',
      },
      firstImageTags: ['image', 'profile'],
      galerieImageTags: ['image', 'gallerie'],
      carouselImageTags: ['image', 'carousel'],
      medias: [] as Media[],
      lockUpdate: true,
      validate: {
        name: '',
        desc: '',
        tag: 'null',
        event_start_date: '',
        event_end_date: '',
      },
    };
  },
  created: function () {
    this.$watch('editorial', this.editorialUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    editorialUpdated: function () {
      this.lockUpdate = false;
    },
    mediasUpdated: function () {
      this.lockUpdate = false;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    deleteAudio(audio: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.media_id !== audio.media_id,
      );
    },
    updateMedias(media: Media[], isSingle: boolean) {
      this.medias = (this as any).updateImagesByTags(this.medias, media, isSingle);
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },
    retrieveGallerie() {
      return (this as any).retrieveMediaByTags(
        ['image', 'gallerie'],
        this.medias,
      );
    },
    retrieveCarousel() {
      return (this as any).retrieveMediaByTags(
        ['image', 'carousel'],
        this.medias,
      );
    },
    cancel(){
      this.$router.push('/content/content-admin');
    },
    validation(): boolean{
      let validationState = true
      if(this.titleSectionData.name === '') {
        this.validate.name = 'false'
        validationState = false
      }else{
        this.validate.name = ''
      }
      if(this.titleSectionData.desc === '') {
        this.validate.desc = 'false'
        validationState = false 
      }else{
        this.validate.desc = ''
      }
      if(this.titleSectionData.tag === 'null') {
        this.validate.tag = 'false'
        validationState = false 
      }else{
        this.validate.tag = 'null'
      }
      if(this.titleSectionData.event_end_date === '' && this.$route.params.type === 'evenement') {
        this.validate.event_end_date = 'false'
        validationState = false
      }else{
        this.validate.event_end_date = ''
      }
      if(this.titleSectionData.event_start_date === '' && this.$route.params.type === 'evenement') {
        this.validate.event_start_date = 'false'
        validationState = false
      }else{
        this.validate.event_start_date = ''
      }
      return validationState
    },
    setProcess(process_module: processModule) {
      this.process_module = process_module
    },
    publish() {
      if (this.validation() === true) {
        let editorialFormData = this.titleSectionData;
        editorialFormData['type'] = this.statutDateAutorData.type;
        editorialFormData['status'] = 'publié';
        this.$emit('save', {
          data: editorialFormData,
          medias: this.medias,
          module_rdv: this.module_rdv,
          process_module: this.process_module
        });
        this.validation()
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if (this.validation() === true) {
        let editorialFormData = this.titleSectionData;
        editorialFormData['type'] = this.statutDateAutorData.type;
        editorialFormData['status'] = 'publié';
        this.editorial.children[0].value.medias = this.medias;
        this.$emit('save', {
          data: editorialFormData,
          medias: this.medias,
          module_rdv: this.module_rdv,
          process_module: this.process_module,
          children: this.editorial.children[0],
        });
        this.validation()
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    save() {
      if (this.validation() === true) {
        let editorialFormData = this.titleSectionData;
        editorialFormData['type'] = this.statutDateAutorData.type;
        editorialFormData['status'] = 'brouillon';
        this.$emit('save', {
          data: editorialFormData,
          medias: this.medias,
          module_rdv: this.module_rdv,
          process_module: this.process_module
        });
        this.validation()
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
  },
  watch: {
    editorial(newVal) {
      if (newVal !== null && typeof newVal === 'object') {
        if(newVal.value){
          this.module_rdv = newVal?.value?.module_rdv;
          if(newVal.value?.process_module) this.process_module =  newVal.value?.process_module;
        }
        this.medias = newVal.children[0].value.medias;
        this.statutDateAutorData.status = newVal.value.status;
        this.statutDateAutorData.type = newVal.value.type;
        this.statutDateAutorData.created_by = newVal.created_by;
        this.statutDateAutorData.created_at = newVal.created_at;
        this.statutDateAutorData.updated_at = newVal.updated_at == null ? newVal.value.updated_at : newVal.updated_at;
        this.statutDateAutorData.published_at = newVal.value.published_at;
      }
    },
  },
  beforeMount() {
    if(this.editorial.value){
      this.module_rdv = this.editorial?.value?.module_rdv;
      if(this.editorial.value.process_module) this.process_module =  this.editorial.value.process_module;
    }
    this.medias = this.editorial.children[0].value.medias;
    this.statutDateAutorData.status = this.editorial.value.status;
    this.statutDateAutorData.type = this.editorial.value.type;
    this.statutDateAutorData.created_by = this.editorial.created_by;
    this.statutDateAutorData.created_at = this.editorial.created_at;
    this.statutDateAutorData.updated_at = this.editorial.updated_at == null ? this.editorial.value.updated_at : this.editorial.updated_at;
    this.statutDateAutorData.published_at = this.editorial.value.published_at;
    if (this.$route.params.type) {
      if (this.$route.params.type == 'evenement')
        this.titleSectionData = {
          name: '',
          desc: '',
          tag: 'evenement',
          type: 'evenement',
          frontpage: true,
          event_start_date: '',
          event_end_date: '',
          published_at: '',
          updated_at: '',
        };
    }
  },
});
