
import { Vue } from 'vue-property-decorator';
import EditionContainer from '@/components/edition-components/edition-container/edition-container.vue';
import TipTapEdition from '@/components/edition-components/tiptap-edition/tiptap-edition.vue';
import NouvelArticleForm from '@/components/forms/nouvel-article-form/nouvel-article-form.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { ContentElement } from '@/models/content_element/content_element';
import { Media } from '@/models/media/media';
import { rdvModule } from '@/models/rdv-module/rdv-module';
import { build } from '@/mixins/build.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
import moment from 'moment';
import { processModule } from '@/models/process/process';
export default Vue.extend({
  data() {
    return {
      editorial: {} as ContentElement,
      tiptapData: '',
      tiptapContent: '',
      blockTiptapData: false
    };
  },
  mixins: [build, nodeMixin],
  components: {
    TipTapEdition,
    NouvelArticleForm,
    EditionContainer,
  },
  computed: {
    ...mapGetters('editorial', ['getEditorial']),
    getData(): ContentElement | null {
      let editorial = null;
      if (this.getEditorial.length !== 0) {
        if (this.$route.params.node_id) {
          this.editorial = this.getEditorial.find(
            (editorial: ContentElement) =>
              editorial.node_id === this.$route.params.node_id,
          );
          editorial = this.editorial
          
          if (Object.keys(this.editorial).length != 0 && !this.blockTiptapData) {
            this.tiptapContent = this.editorial.value.display_content.html;
          }
        }else{
          editorial = {} as ContentElement
        }
      }else{
        editorial = {} as ContentElement
      }
      
      return editorial;
    }
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('editorial', [
      'loadEditorial',
      'addArticle',
      'updateContent',
      'updateContentChildren',
    ]),
    ...mapActions('media', ['updateMediasNode']),
    haveData() {
      return this.getData !== null
    },
    save(editorialFormData: any) {
      this.blockTiptapData = true
      editorialFormData.data['display_content'] = this.tiptapData;
      let process_module = editorialFormData.process_module?.id ? editorialFormData.process_module : null

      if (this.$route.params.node_id)
        this.updateContentElement(
          editorialFormData.data,
          editorialFormData.medias,
          editorialFormData.module_rdv,
          process_module
        );
      else
        this.addContentElement(
          editorialFormData.data,
          editorialFormData.medias,
          editorialFormData.module_rdv,
          process_module
        );
    },
    addContentElement(data: any, medias: Array<Media>, module_rdv: rdvModule, process_module: processModule) {

      let slug = (this as any).slugify(data.name)
      const editorial = {
        name: data.name,
        desc: data.desc,
        slug: slug,
        type: data.type,
        status: data.status,
        display_content: data.display_content,
        module_rdv: module_rdv,
        process_module: process_module,
        frontpage: data.frontpage,
        updated_at: moment().format(),
        published_at: '',
        tag: data.tag,
        event_start_date: data.event_start_date,
        event_end_date: data.event_end_date,
      };

      if (editorial.status == 'publié') editorial.published_at = moment().format();

      const node = (this as any).buildCreatePayload(
        this.$data.required_nodes,
        'editorial',
        NodeType.content,
        editorial,
      );

      const mediaNode = (this as any).buildMediaPayload(
        node.contents_requests[0].navigation_path,
        medias,
      );

      const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

      this.addArticle(dto).then(() => {
        this.$router.push('/content/content-admin');
      });
    },
    updateContentElement(
      data: any,
      medias: Array<Media>,
      module_rdv: rdvModule,
      process_module: processModule
    ) {
      if (data.published_at == '' || data.published_at == undefined) data.published_at = moment().format();

      let slug = (this as any).slugify(data.name)
      data.updated_at = moment().format();
      data.module_rdv = module_rdv;
      data.process_module = process_module;
      data.slug = slug;
      this.editorial.value = data;
      const mediaNavigationPath = this.editorial.navigation_path + '/medias';
      const mediaNode = (this as any).findNodeInTree(
        mediaNavigationPath,
        this.editorial,
      );
      mediaNode.value.medias = medias;
      this.updateMediasNode(mediaNode).then(() => {
        
        this.updateContent(this.editorial);
        this.$router.push('/content/content-admin');
      });
    },
  },
});
